"use strict";
class AdminInviteController {
    constructor($element, $parse, $window, SSHttpService, SSUtilService, SSAlertService, SSUserService, SSConfirmationService, OTPService, SSAnalyticsService) {
        const self = this;

        self.$element = $element;
        self.$parse = $parse;
        self.$window = $window;

        self.SSHttpService = SSHttpService;
        self.SSUtilService = SSUtilService;
        self.SSAlertService = SSAlertService;
        self.SSUserService = SSUserService;
        self.SSConfirmationService = SSConfirmationService;
        self.SSAnalyticsService = SSAnalyticsService;
        self.OTPService = OTPService;
        self.data = SHOWSUITE.data;
        self.showRolePermissionList = false;
        self.userInviteSettings = false;
        self.userInviteId = null;
        self.userInviteRoleId   = null;
        self.userInviteRoleName = null;
        self.userInvitePermissions = null;
        self.responseData = null;

        self.has_access = false;
        if(self.SSUserService.isSuper() || self.SSUserService.isDeveloper() || self.SSUserService.isAMP()) {
            self.init();
            self.has_access = true;
        }
    }

    init() {
        const self = this;

        // Trigger View Event for User Invites Page
        self.SSAnalyticsService.saveAnalyticEvent({
                event: self.SSAnalyticsService.ANALYTICS_EVENT_VIEWS_DMS_USER_INVITES,
                user_id: self.SSUtilService.getFromLocalStorage('user').id
            }
        );

        self.userInviteSettings = self.data.user_invite_settings;

        if(self.userInviteSettings){
            self.userInviteId = self.data.id;
            self.getUserInviteSettings();
        }
        else{
            self.getInvites();
        }
    }

    getUserInviteSettings(){
        const self = this;

        let url = 'sec/developer/users/' + self.userInviteId;

        loading(true);
        self.SSHttpService.getAPIRequest(url).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }
            self.responseData = response.data;
            self.invitedUser = response.data.invited_user;
            self.userInviteRoleId = self.responseData.invited_user_role_id;
            self.userInviteRoleName = self.responseData.invited_user_role_name;
            self.userInvitePermissions = self.responseData.invited_user_permissions;
        });
    }

    getInvites() {
        const self = this;

        self.allowedRoles = [];
        self.userInvites = [];
        self.roles = [];
        self.rolePermissions = [];
        self.rolePermissionFilter =[];

        let url = 'sec/invites';

        loading(true);
        self.SSHttpService.getAPIRequest(url).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            self.allowedRoles = response.data.allowed_roles;
            self.userInvites = response.data.user_invites;
            self.roles = response.data.user_invites;
            self.rolePermissions = response.data.permissions;
        });
    }

    toggleInviteView(show) {
        const self = this;

        self.showCreateInviteView = show;
        self.showRolePermissionList = show;

        if (self.showCreateInviteView) {
            self.inviteParams = {};
            self.inviteErrors = {};
        }
    }

    sendInvite() {
        const self = this;

        self.OTPService.getVerified(
            self.OTPService.TYPE_SESSION_2FA,
            function(data){

                if (!self.SSUtilService.validateForm(self, 'ctrl', self.$element))
                    return;

                self.inviteErrors = {};
                let url = 'sec/invites';

                loading(true);
                self.SSHttpService.postAPIRequest(url, self.inviteParams).then(function (response) {
                    loading(false);

                    if (response instanceof Error) {
                        self.SSAlertService.parseAndDisplayError(response);

                        jQuery.each(response.data, function (key, value) {
                            try {
                                self.inviteErrors[key] = value[0];
                            } catch (ex) {
                            }
                        });

                        return;
                    }

                    self.SSAlertService.success('Invite sent successfully.');

                    self.toggleInviteView(false);

                    let invite = response.data.user_invite;
                    let found = false;

                    jQuery.each(self.userInvites, function (i, _invite) {
                        if (invite.id == _invite.id) {
                            angular.merge(_invite, invite);
                            found = true;
                            return false;
                        }
                    });

                    if (!found) {
                        self.userInvites.push(invite);
                    }
                });

            });

    }

    deleteInvite(inviteId) {
        const self = this;

        if (!inviteId)
            return;

        self.SSConfirmationService.getConfirmed(
            'Delete!',
            'Are you sure you want to delete this invite?',
            self.SSConfirmationService.TYPE_DEFAULT,
            function() {
                self._deleteInvite(inviteId);
            }
        );
    }

    _deleteInvite(inviteId) {
        const self = this;

        let url = 'sec/invites/' + inviteId + '/delete';

        loading(true);
        self.SSHttpService.getAPIRequest(url).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            self.SSAlertService.success('Invite deleted successfully.');

            jQuery.each(self.userInvites, function (i, invite) {
                if (invite.id == inviteId) {
                    self.userInvites.splice(i, 1);
                    return false;
                }
            });
        });
    }

    getRoleName(roleId) {
        const self = this;

        let roleName = '';

        if (roleId && self.allowedRoles) {
            jQuery.each(self.allowedRoles, function (i, role) {
                if (role.id == roleId) {
                    roleName = role.display_name;
                    return false;
                }
            });
        }

        return roleName;
    }

    clipboardCopy(invite) {
        const self = this;

        let url = self.$window.location.origin + '/register?invite_code=' + invite.code;

        self.SSUtilService.clipboardCopy(url);
    }

    validationInvite(){
        const self = this;

        let error = false;
        self.errName = '';
        self.errEmail = '';
        self.errRole = '';

        if (!self.inviteErrors.display_name) {
            self.errName = 'Name is required';
            error = true;
        }
        if (!self.inviteParams.email) {
            self.errEmail = 'Email is required';
            error = true;
        }

        if (!self.inviteParams.role_name) {
            self.errRole = 'Role is required';
            error = true;
        }

        return !error;
    }

    getPermission(role){
        const self = this;

        self.showRolePermissionList = true;
        self.rolePermissionFilter =[];

        jQuery.each(self.rolePermissions, function (i, permission) {
            if (permission.role_id == role) {
                self.rolePermissionFilter.push(permission);
            }
        });
    }
    
    getInviteUserPermissions(roleId){
        const self = this;

        // let url = 'sec/developer/users/user-invites-permissions/'+self.userInviteId+'/'+roleId;
        let url = 'sec/developer/users/' + self.userInviteId + '/roles/' + roleId;

        loading(true);
        self.SSHttpService.getAPIRequest(url).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }
            self.userInvitePermissions = response.data.all_permissions;
        });
    }

    updateInviteUserRole(roleId){
        const self = this;

        let url = 'sec/developer/users/'+ self.userInviteId;
        let params = {
            role_id: roleId,
            status: self.invitedUser.status
        };

        self.SSConfirmationService.getConfirmed(
            'Update '+self.invitedUser.first_name+'  '+self.invitedUser.last_name+' Role!',
            'Are you sure you want to update role?',
            self.SSConfirmationService.TYPE_DEFAULT,
            function() {
                loading(true);

                self.SSHttpService.putAPIRequest(url, params).then(function (response) {
                    loading(false);
                    if (response instanceof Error) {
                        self.SSAlertService.parseAndDisplayError(response);
                        return;
                    }
                    self.userInvitePermissions = response.data.all_permissions;
                    self.getInviteUserPermissions(roleId);
                    self.SSAlertService.success('Role updated successfully');

                });
            }
        );

    }

    toggleAccountActivation(user){
        const self = this;

        var status = user.status;
        var activate = true;

        // status = 1 -> activated
        // status = 2 -> not active
        if(user.status == 1){
            status = 2;
            activate = false;
        }
        else if(user.status == 2){
            status = 1;
        }

        let url = 'sec/developer/users/' + user.id;
        let params = {
            role_id: self.userInviteRoleId,
            status: status
        };

        self.SSConfirmationService.getConfirmed(
            (activate ? 'Activate' : 'Deactivate') + ' account',
            'Are you sure you want to ' + (activate ? 'activate' : 'deactivate') + ' account?',
            self.SSConfirmationService.TYPE_DEFAULT,
            function() {
                loading(true);

                self.SSHttpService.putAPIRequest(url, params).then(function (response) {
                    loading(false);
                    if (response instanceof Error) {
                        self.SSAlertService.parseAndDisplayError(response);
                        return;
                    }
                    self.invitedUser.status = status;
                    self.SSAlertService.success('Account ' + (activate ? 'activated' : 'deactivated') + ' successfully');

                });
            }
        );
    }

    forgetPassword() {

        const self = this;

        let params = {
            'user_email': self.invitedUser.email
        };

        self.forgetPasswordInProcess = true;
        self.forgetPasswordError = null;
        self.forgetPasswordSuccess = null;
        self.SSConfirmationService.getConfirmed(
            'Send Reset Password Email!',
            'Are you sure you want to reset password?',
            self.SSConfirmationService.TYPE_DEFAULT,
            function() {
                self.SSHttpService.postAPIRequest('forget-password', params).then(
                    function (responseObj) {
                        self.forgetPasswordInProcess = false;

                        if (responseObj instanceof Error) {
                            self.forgetPasswordError = responseObj.message;
                            return;
                        }

                        self.forgetPasswordSuccess = responseObj.message;
                        self.SSAlertService.success(self.forgetPasswordSuccess);

                    });
            }
        );
    }

}

AdminInviteController.$inject = ['$element', '$parse', '$window', 'SSHttpService', 'SSUtilService', 'SSAlertService', 'SSUserService', 'SSConfirmationService', 'OTPService', 'SSAnalyticsService'];
app.controller('AdminInviteController', AdminInviteController);
